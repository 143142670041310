import React, { FC } from "react"
import styled from "styled-components"

// components
import PyramidProgress from "../../PyramidProgress"
import Button from "../../atoms/Button"

// Hooks
import { useLocale } from "../../../hooks/useLocale"

// utils
import photoShape from "../../../images/photo-shape.svg"
import mobileShape from "../../../images/photo-shape-mobile.svg"

// Constants
import { xmlNamespace } from "../../../constants/svgUrl"

// styles
import colors from "../../../styles/colors"
import { fontWeights } from "../../../styles/textStyles"
import mq from "../../../styles/breakpoints"
import { fonts } from "../../../styles/fonts"

// Types
import { IServiceCard } from "../../../prismic-types/slices/services-cards"

const ServiceCard: FC<IServiceCard> = ({
  image,
  serviceLevel,
  button,
  description,
  heading,
  serviceType,
  serviceTypeColor,
  title,
}) => {
  const { getLocalizedLink } = useLocale()
  const commonProps = {
    width: 49,
    height: 37,
    fill: "none",
    xmlns: xmlNamespace,
    viewBox: "0 0 49 37",
    color: colors.purpleLight,
  }

  return (
    <Slide>
      {image.fluid && <ImageWrapper bg={image.fluid.src} />}
      <Info>
        <LabelAndIconWrapper>
          <Label color={serviceTypeColor}>{serviceType}</Label>
          <SVG>
            <PyramidProgress level={serviceLevel} commonProps={commonProps} />
          </SVG>
        </LabelAndIconWrapper>
        <div>
          <SlideTitle>{title}</SlideTitle>
          <SlideSubtitle color={serviceTypeColor}>{heading}</SlideSubtitle>
        </div>
        <Paragraph>{description}</Paragraph>
        {button && (
          <StyledButton
            variant="secondary"
            label={button.label}
            href={getLocalizedLink(button.href)}
          />
        )}
      </Info>
    </Slide>
  )
}

export default ServiceCard

const Slide = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  box-shadow: 0 16px 35px rgba(82, 78, 6, 0.0417),
    0 25px 64px rgba(82, 78, 6, 0.04);

  ${mq.from.M`
    flex-direction: row;
    border-radius: 32px;
  `}
`

const ImageWrapper = styled.div<{ bg?: string }>`
  width: 100%;
  order: 0;
  background-color: ${colors.greyLight};
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 296px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    mask: url("${mobileShape}");
    mask-size: cover;
    width: 100%;
    height: 40px;
    background-color: ${colors.white};

    ${mq.from.XS`
      height: 56px;
    `}

    ${mq.from.S`
      height: 64px;
    `}

    ${mq.from.M`
      bottom: initial;
      top: 0;
      mask: url("${photoShape}");
      mask-size: cover;
      height: 100%;
      width: 60px;
    `}
  }

  ${mq.from.S`
    height: 392px;
  `}

  ${mq.from.M`
    order: 2;
    width: 50%;
    height: initial;
  `}

  img {
    margin: 0;
  }
`

const Info = styled.div`
  order: 1;
  padding: 16px;
  width: 100%;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  ${mq.from.S`
    padding: 48px;
  `}

  ${mq.from.M`
    width: 50%;
    padding: 48px 64px;
  `}
`
const LabelAndIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SVG = styled.div``

const Label = styled.h4<{ color: string }>`
  font-family: ${fonts.primary};
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 2px
  font-weight: ${fontWeights.medium};
  background: ${({ color }) => color};
  color: ${colors.white};
  border-radius: 6px;
  padding: 4px 16px;
  margin-bottom: 0px;

  ${mq.to.S`
    position: absolute;
    top: 16px;
    left: 16px;
  `}
`

const SlideTitle = styled.h2`
  font-size: 28px;
  line-height: 36px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 40px;
    line-height: 52px;
  `}
`

const SlideSubtitle = styled.h5<{ color: string }>`
  font-family: ${fonts.primary};
  font-size: 18px;
  line-height: 26px;
  font-weight: ${fontWeights.medium};
  color: ${({ color }) => color};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 22px;
    line-height: 30px;
  `}
`

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1%;
  font-weight: ${fontWeights.regular};
  color: ${colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 18px;
    line-height: 30px;
  `}
`

const StyledButton = styled(Button)`
  width: 100%;

  ${mq.from.M`
    width: auto;
  `}
`
